import React, { useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import { Flex, Box, Badge, PseudoBox, Button } from "@chakra-ui/core"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1 } from "../components/Typography"
import firebase from "gatsby-plugin-firebase"

export function ArticleCard({ article, ...rest }) {
  return (
    <PseudoBox
      _hover={{
        boxShadow: "xl",
      }}
    >
      <Link to={`/${article.fields.slug}`}>
        <Flex
          borderWidth="1px"
          boxShadow="sm"
          rounded="lg"
          overflow="hidden"
          flex={1}
          w="250px"
          minH="315px"
          direction="column"
          {...rest}
        >
          {article.featuredImage && (
            <Img
              fixed={article.featuredImage.childImageSharp.fixed}
              alt="featuredImage"
              width="250x"
              height="140px"
            />
          )}
          <Flex paddingX={4} paddingY={2} flex={1} direction="column">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="blue">
                {article.articleCategories?.name || null}
              </Badge>
              <Flex direction="column">
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="xs"
                  textTransform="uppercase"
                  ml="2"
                >
                  {article.fields.date}
                </Box>
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="xs"
                  textTransform="uppercase"
                  ml="2"
                >
                  {article.fields?.timeToRead}
                </Box>
              </Flex>
            </Box>

            <Flex
              direction="column"
              justify="space-between"
              align="center"
              flex={1}
            >
              <Box
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                textAlign="center"
              >
                {article.title}
              </Box>
              <Button variant="ghost" variantColor="Gray" color="Gray" w="100%">
                View
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </PseudoBox>
  )
}

export default function ArticleListTemplate({ data, pageContext, ...rest }) {
  const articles = data.allArticle.edges || []
  const { currentPage, numPages } = pageContext || {}
  const hasMore = numPages > currentPage
  const hasLess = currentPage > 1

  useEffect(() => {
    firebase.analytics().setCurrentScreen(`article_page_${currentPage}`)
  }, [])

  return (
    <Layout>
      <SEO title="News" />
      <H1 textAlign="center">News</H1>
      <Flex
        direction={["column", "row"]}
        justify={["center", "space-between"]}
        align={["center", "flex-start"]}
        wrap="wrap"
        flex={1}
      >
        {articles.map(article => (
          <ArticleCard article={article.node} key={article.node.id} mt={8} />
        ))}
      </Flex>
      <Flex justify="center" align="center" w="100%" mt={8}>
        <Button
          isDisabled={!hasLess}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage > 2) {
              navigate(`/articles/${currentPage - 1}`)
            } else navigate(`/articles`)
          }}
        >
          Previous
        </Button>
        <Flex marginX={4}>
          Page {currentPage} of {numPages}
        </Flex>
        <Button
          isDisabled={!hasMore}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage < numPages) {
              navigate(`/articles/${currentPage + 1}`)
            }
          }}
        >
          Next
        </Button>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query articleList($skip: Int!, $limit: Int!) {
    allArticle(
      skip: $skip
      limit: $limit
      sort: { fields: fields___date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          summary
          articleCategories {
            name
          }
          featuredImage {
            childImageSharp {
              fixed(width: 250, height: 140) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          otherImagesLinks
          description
          fields {
            slug
            timeToRead
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
`
